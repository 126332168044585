<template>
  <b-card class="mb-5">
    <h4 class="font-weight-bold">
      {{ $t('drivers.titles.driversDocsChecking') }}
    </h4>

    <driver-container-history-apps :driver-id="$route.params.id" @shared="apps => (this.listApps = apps)">
      <driver-view-approval-history-apps v-if="listApps && listApps.length > 0" :list-apps="listApps" />
    </driver-container-history-apps>

    <!-- CNH -->
    <driver-view-approval-cnh v-if="$regions.isBrazil()" :driver-id="driverId" />
    <driver-view-approval-cnhMX v-if="$regions.isMexico()" :driver-id="driverId" />

    <!-- Address -->
    <driver-view-approval-address :driver-id="driverId" />

    <!-- Buttons and Actions -->
    <driver-view-approval-actions :driver-id="driverId" />
  </b-card>
</template>

<script>
import DriverContainerHistoryApps from '@components/drivers/history-apps/driver-container-history-apps';
import DriverViewApprovalActions from '@components/drivers/proof-docs/driver-view-approval-actions';
import DriverViewApprovalAddress from '@components/drivers/proof-docs/driver-view-approval-address';
import DriverViewApprovalCnh from '@components/drivers/proof-docs/driver-view-approval-cnh';
import DriverViewApprovalCnhMX from '@components/drivers/proof-docs/driver-view-approval-cnhMX';
import DriverViewApprovalHistoryApps from '@components/drivers/history-apps/driver-view-approval-history-apps';

export default {
  name: 'DriverPendingDocs',
  components: {
    DriverContainerHistoryApps,
    DriverViewApprovalActions,
    DriverViewApprovalAddress,
    DriverViewApprovalCnh,
    DriverViewApprovalCnhMX,
    DriverViewApprovalHistoryApps
  },
  props: {
    driverId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      listApps: []
    }
  },
  mounted() {
  }
};
</script>
